import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { Link } from "gatsby"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <div className="container">
      <div className="row justify-content-center py-5">
        <div className="col-md-10 text-center">
        <h1>Page Not Found</h1>
        <p>Sorry! This page doesn't exist.</p>
        <Link to="/" className="btn btn-primary">Go home</Link>
        </div>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
